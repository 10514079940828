import React from "react";
import Cardabout from "../../component/Cardabout/Cardabout";

import logo from "../../Media/Logo/regialogo.png";
import Card from "../../component/Card/Card";
import data from "./data.json";
import "../Viola/Viola.scss";
import regia5 from "../../Media/Product/Regia 5";
import regia6 from "../../Media/Product/Regia 6";
import regia7 from "../../Media/Product/Regia 7";
import regia8 from "../../Media/Product/Regia 8";
import regiaabout from "../../Media/Cardabout/Regia";

const Regia = () => {
  const imageMap = {
    regia5,
    regia6,
    regia7,
    regia8,
  };

  return (
    <div id="rumahmodern">
      <Cardabout
        logo={logo}
        carousel={regiaabout}
        des1={
          "Nama Regia diambil dari nama latin bunga flamboyan, Delonix regia. Karena biasanya digunakan sebagai tanaman hias, sebagian besar bunganya akan ditanam di taman dan sepanjang jalan untuk menghiasi cluster."
        }
        des2={
          "Dengan konsep modern kontemporer, Regia Residence menyediakan hunian sempurna bagi keluarga baru untuk tumbuh dan berkembang bersama, menyeimbangkan kehidupan modern dikelilingi oleh nuansa alam yang sejuk."
        }
      />
      <div className="cardsection">
        {data.cards.map((card, index) => {
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
              styletitle={card.styletitle}
              styleprice={card.styleprice}
              iconcolor={card.iconcolor}
              stylebutton={card.stylebutton}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Regia;
