import Vanica1 from '../Vanica 9/Vanica 9 (1).webp'
import Vanica2 from '../Vanica 9/Vanica 9 (2).webp'
import Vanica3 from '../Vanica 9/Vanica 9 (3).webp'
import Vanica4 from '../Vanica 9/Vanica 9 (4).webp'
import Vanica5 from '../Vanica 9/Vanica 9 (5).webp'
import Vanica6 from '../Vanica 9/Vanica 9 (6).webp'
import Vanica7 from '../Vanica 9/Vanica 9 (7).webp'
import Vanica8 from '../Vanica 9/Vanica 9 (8).webp'
import Vanica9 from '../Vanica 9/Vanica 9 (9).webp'
import Vanica10 from '../Vanica 9/Vanica 9 (10).webp'
import Vanica11 from '../Vanica 9/Vanica 9 (11).webp'
import Vanica12 from '../Vanica 9/Vanica 9 (12).webp'
import Vanica13 from '../Vanica 9/Vanica 9 (13).webp'

const Vanica9images =[
Vanica1,Vanica2,Vanica3,Vanica4,Vanica5,Vanica6,Vanica7,Vanica8,Vanica9,Vanica10,Vanica11,Vanica12,Vanica13
]

export default Vanica9images;