import React from "react";
import "./Vanika.scss";
import Myztro1 from "./Vanika/Vanika1.jsx";

const apartemen = () => {
  return (
    <div id="Vanica" className="apartment">
      <div className="container-header1"> New Launching Vanica</div>
      <div className="container-header2"> At Summarecon Crown Gading</div>
      <div>
        <div className="Judul"></div>
        <Myztro1 />
      </div>
    </div>
  );
};

export default apartemen;
