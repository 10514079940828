import Kawasan1 from '../../../src/Media/Kawasan/Kawasan (1).webp'
import Kawasan2 from '../../../src/Media/Kawasan/Kawasan (2).webp'
import Kawasan3 from '../../../src/Media/Kawasan/Kawasan (3).webp'
import Kawasan4 from '../../../src/Media/Kawasan/Kawasan (4).webp'
import Kawasan5 from '../../../src/Media/Kawasan/Kawasan (5).webp'
import Kawasan6 from '../../../src/Media/Kawasan/Kawasan (6).webp'
import Kawasan7 from '../../../src/Media/Kawasan/Kawasan (7).webp'
import Kawasan8 from '../../../src/Media/Kawasan/Kawasan (8).webp'


const Kawasanimages =[
Kawasan1,Kawasan2,Kawasan3,Kawasan4,Kawasan5,Kawasan6,Kawasan7,Kawasan8,
]

export default Kawasanimages;