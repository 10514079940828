import Vanica1 from '../Vanica 7/Vanica 7 (1).webp'
import Vanica2 from '../Vanica 7/Vanica 7 (2).webp'
import Vanica3 from '../Vanica 7/Vanica 7 (3).webp'
import Vanica4 from '../Vanica 7/Vanica 7 (4).webp'
import Vanica5 from '../Vanica 7/Vanica 7 (5).webp'
import Vanica6 from '../Vanica 7/Vanica 7 (6).webp'
import Vanica7 from '../Vanica 7/Vanica 7 (7).webp'
import Vanica8 from '../Vanica 7/Vanica 7 (8).webp'


const Vanica7images =[
Vanica1,Vanica2,Vanica3,Vanica4,Vanica5,Vanica6,Vanica7,Vanica8
]

export default Vanica7images;