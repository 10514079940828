import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footercontainer">
      <div className="footertitle">Marketing Gallery</div>
      <div className="footersubtitle">Summarecon Crown Gading</div>
      <div className="footeralamat">
        Setia Asih, Tarumajaya, Bekasi Regency, West Java 17215
      </div>
      <div className="footercontact">Contact Us : +6281223452908</div>
    </div>
  );
};

export default Footer;
