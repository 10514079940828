import React from "react";
import promoimg from "../../Media/Promo.jpeg";
import "./Promo.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons";

const Promo = () => {
  const item = [
    "Subsidi DP",
    "Insentif Hunia Ratusan Juta",
    "Free Canopy",
    "Free Smartdoor Lock",
    "Free CCTV",
    "Voucher Belanja Puluhan Juta",
    "Free Iphone 15 Promax*",
    "Instan Approval",
  ];

  return (
    <div className="promocontainer" id="promo">
      <div className="promoimg">
        <img className="img" alt="promoscg" src={promoimg} />
      </div>
      <div className="promocontent">
        <div className="title">PROMO</div>
        <div className="promoitem">
          {item.map((item, index) => (
            <div className="item" key={index}>
              <FontAwesomeIcon icon={faCircleCheck} />
              &nbsp; &nbsp;
              {item}
            </div>
          ))}
        </div>
        <div className="buttonpenawaran">
          <button
            className="wapromo"
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=6285931970966&text=Halo,%20Billy,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20https://scg-crowngading.id/&type=phone_number&app_absent=0",
                "_blank"
              )
            }>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promo;
