import React from "react";
import "./future.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Kawasanimages from "./kawasan.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div id="progress" className="futureofscg">
      <div className="container-futureofscg">
        <div className="judul-futureofscg">
          <h1>Progress of Summarecon Crown Gading</h1>
        </div>
        <div className="container-futureofscg">
          <div className="kartu">
            <Slider {...settings}>
              {Kawasanimages.map((image, index) => (
                <div>
                  <img
                    className="kartugambar1"
                    key={index}
                    src={image}
                    alt="athena"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
