import regiaabout1 from "./Untitled-design-6-1.png";
import regiaabout2 from "./Untitled-design-7.png";
import regiaabout3 from "./Screenshot_3.png";
import regiaabout4 from "./Screenshot_1.png";
import regiaabout5 from "./8.png";

const regiaabout = [
  regiaabout1,
  regiaabout2,
  regiaabout3,
  regiaabout4,
  regiaabout5,
];

export default regiaabout;
