import jasmiaabout1 from "./12-2.webp";
import jasmiaabout2 from "./20.webp";
import jasmiaabout3 from "./21.webp";
import jasmiaabout4 from "./22-1.webp";
import jasmiaabout5 from "./23.webp";
import jasmiaabout6 from "./24.webp";
import jasmiaabout7 from "./25.webp";
import jasmiaabout8 from "./26.webp";
import jasmiaabout9 from "./27.webp";
import jasmiaabout10 from "./28.webp";
import jasmiaabout11 from "./29.webp";
import jasmiaabout12 from "./30.webp";
import jasmiaabout13 from "./31.webp";
import jasmiaabout14 from "./32.webp";
import jasmiaabout15 from "./33.webp";
import jasmiaabout16 from "./34.webp";
import jasmiaabout17 from "./35.webp";

const jasmiaabout = [
  jasmiaabout1,
  jasmiaabout2,
  jasmiaabout3,
  jasmiaabout4,
  jasmiaabout5,
  jasmiaabout6,
  jasmiaabout7,
  jasmiaabout8,
  jasmiaabout9,
  jasmiaabout10,
  jasmiaabout11,
  jasmiaabout12,
  jasmiaabout13,
  jasmiaabout14,
  jasmiaabout15,
  jasmiaabout16,
  jasmiaabout17,
];

export default jasmiaabout;
